import React from "react"
import { Link } from "gatsby"
import { addClassName } from "../../utils"

export default function LinkButton({ to, children, ...props }) {
  if(to === undefined) {
    return null
  }
  else if (to.startsWith('http') || to.startsWith('/static/')) {
    return (
      <a href={to} {...addClassName("btn", props)} target="_blank" >
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} {...addClassName("btn", props)} >
        {children}
      </Link>
    )
  }
}