import React from "react"
import moment from "moment"
import { BiArrowFromLeft } from "react-icons/bi"

function Event({ event }) {
  const start = moment(`${event.date} ${event.startTime}`, 'YYYY-MM-DD HH:mm')
  return (
    <li key={event.id} className="list-group-item list-group-item-action px-0 py-1">
      <time dateTime={start.format('YYYY-MM-DD[T]HH:MM:00')}>
        {start.format('D MMM \'YY, HH:mm') }
      </time>{` `}
      <span>{event.location}</span>{` `}
      <span>
        { event.link
          ? <a href={event.link}><BiArrowFromLeft /></a>
          : null
        }
      </span>
    </li>
  )
}


export default function Events({ events }) {
  return (
    <ul className="events small list-group">
      {events.map(event => <Event event={event} key={event.id} />)}
    </ul>
  )
}