import React from "react"
import { BiArrowToBottom, BiArrowFromLeft, BiGitRepoForked } from "react-icons/bi"
import CardButton from "../ui/card-button"
import CiteButton from "./cite-button"

export default function BibItemOptions({ project, citeEntry, ...props }) {
  return (
    <div {...props}>
      {project.pdf
        ? <CardButton to={project.pdf.publicURL}>
            <BiArrowToBottom /> pdf
          </CardButton> 
        : null }

      { (project.supplements.length > 0)
        ? (
          project.supplements.map((suppl, i) =>
            <CardButton to={suppl.publicURL} key={suppl.publicURL}>
              <BiArrowToBottom /> 
              {` supplement ${(project.supplements.length > 1) ? i + 1 : ''}`}
            </CardButton> 
          )
        ) : null }

      {project.slides
        ? <CardButton to={project.slides.publicURL}>
            <BiArrowToBottom /> slides
          </CardButton> 
        : null}

      {project.poster
        ? <CardButton to={project.poster.publicURL}>
            <BiArrowToBottom /> poster
          </CardButton>
        : null}

      { project.repository
        ? <CardButton to={project.repository}>
            <BiGitRepoForked /> repo
          </CardButton> 
        : null}
      
      { project.doi
        ? <CardButton to={project.doi}>
            <BiArrowFromLeft /> doi
          </CardButton> 
        : null}
      
      { project.links
        ? (
          project.links.map((url, i) =>
            <CardButton to={url} key={url}>
              <BiArrowFromLeft /> 
              {` link ${(project.links.length > 0) ? i + 1 : null}`}
            </CardButton> 
          )
        ) : null }
      
      { citeEntry ? <CiteButton entry={citeEntry} /> : null }
    </div>
  )
}
