import React from "react"
import LinkButton from "./link-button"
import { addClassName } from "../../utils"

export default function CardButton({ to, children, ...props }) {
  return (
    <LinkButton to={to} {...addClassName("btn btn-sm btn-outline-primary", props)}>
      { children }
    </LinkButton>
  )
}