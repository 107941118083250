import React from "react"
import Card from "react-bootstrap/Card"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import ReactMarkdown from "react-markdown"

function NewsItem({ date, message, tags }) {
  const d = new Date(date.trim())
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const month = months[d.getMonth()]
  const year = (d.getYear() + 1900).toString().slice(-2)
  const formattedDate = `${d.getDate()} ${month} '${year}`
  return (
    <Card className="mt-0 mb-0">
      <Card.Body className="p-0">
        <Card.Title className="mb-0">
          <time datetime={date} className="d-inline">{formattedDate}</time>
        </Card.Title>
        <ReactMarkdown children={message} className="text-book" />
      </Card.Body>
    </Card>
  )
}

function News({ items, columnsCountBreakPoints = {350: 1, 600: 2, 1200: 3}}={}) {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
      <Masonry gutter={20}>
        {
          items?.map(item => 
            <NewsItem date={item.date} message={item.message} />
          )
        }
      </Masonry>
    </ResponsiveMasonry>
  )
}

export default News