import React from "react"
import Cite from "citation-js"
import Card from "react-bootstrap/Card"
import ProjectFeature from "./project-feature"
import BibItemOptions from "../../components/bibliography/options"

export default function ResearchFeature({ project, bibliography, ...props }) {
  let citeEntry;
  if(project.bibtexKey) {
    citeEntry = new Cite(bibliography[project.bibtexKey])
  }
  return (
    <ProjectFeature project={project} {...props}>
      <Card.Footer className="small text-muted">
        <span className="btn btn-disabled btn-sm">
          {project.monthYear}
        </span>
        <BibItemOptions project={project} citeEntry={citeEntry} className="d-inline" />
      </Card.Footer>
    </ProjectFeature>
  )
}