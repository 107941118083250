import React from "react"
import _ from "lodash"
import Card from "react-bootstrap/Card"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "react-bootstrap/Carousel"
import ReactMarkdown from "react-markdown"
import clip from "text-clipper"
import Events from "../events"

function ImageCaroussel({ images, pause, interval, slide, title } = {}) {
  slide = (slide !== true) ? false : true;
  interval = (interval === null) ? 5000 : interval
  if(pause !== false) interval = 1000*60*60*24

  return (
    <Carousel interval={interval} slide={slide}>
      {images.map((image, index) =>
        <Carousel.Item key={image.id}>
          <GatsbyImage image={image}
            alt={`Image ${index} of project '${title}'`} />
        </Carousel.Item>
      )}
    </Carousel>
  )
}

function Header({ images, title }) {
  return (
    <Card.Header className="p-0">
      { 
        (images.length > 1)
          ? <ImageCaroussel images={images} title={title} />
          : <GatsbyImage image={images[0]} 
              alt={`Image 1 of project '${title}'`} />
      }
    </Card.Header>
  )
}

export default function ProjectFeature({ project, children, ...props }) {
  let excerpt;
  if(project.oneliner) {
    excerpt = project.oneliner
  } else if(project.popularSummary) {
    excerpt = clip(project.popularSummary, 200, {maxLines: 2})
  } else if(project.abstract) {
    excerpt = clip(project.abstract, 200, {maxLines: 2})
  }
  const images = project.images
    .filter(img => img !== null)
    .map(img => getImage(img))

  return (
    <Card {...props}>
      {images.length > 0
        ? <Header images={images} title={project.title} />
        : null}
      <Card.Body className="pb-0">
        <Card.Title>{project.title}</Card.Title>
        { excerpt 
          ? <ReactMarkdown children={excerpt} className="card-text text-book"/>
          : null }
        { project.events 
          ? <Events events={project.events} />
          : null }
      </Card.Body>
      { children }
    </Card>
  )
}