import React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import copy from "copy-text-to-clipboard"
import { BiPlus, BiCopy } from "react-icons/bi"

const DropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button 
    type="button" 
    className="btn btn-sm btn-outline-primary dropdown-toggle no-icon"
    aria-haspopup="true" 
    aria-expanded="false" 
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
      {children}
  </button>
));

function cite(entry, format) {
  if(format === 'bibtex') {
    return entry.format('bibtex')
  } else {
    return entry.format('bibliography', {
        template: "apa",
    })
  }
}

export default function CiteButton({ entry }) {
  return (
    <Dropdown className="d-inline-block">
      <Dropdown.Toggle as={DropdownToggle}>
        <BiPlus /> cite
      </Dropdown.Toggle>
      <Dropdown.Menu className="small">
        <Dropdown.Item onClick={() => { copy(cite(entry, 'bibtex')) }} className="small">
          <BiCopy /> copy bibtex
        </Dropdown.Item>
        <Dropdown.Item onClick={() => { copy(cite(entry, 'apa')) }} className="small">
          <BiCopy /> copy apa
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) 
}
