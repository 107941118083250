import React from "react"
import ResearchFeature from "./research-feature"
import DesignFeature from "./design-feature"
import MusicFeature from "./music-feature"
import ProjectFeature from "./project-feature"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export default function Features({ projects, type, ...props }) {
  // Use the same Feature component for all projects
  if(type === "research") {
    type = ResearchFeature
  } else if(type === "design") {
    type = DesignFeature
  } else if(type === "music") {
    type = MusicFeature
  }
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 550: 2, 1200: 3}}>
      <Masonry gutter={40}>
        {projects.map(project => {
          // Dynamically determine Feature component using project.__typename
          let projectType = type;
          if(type === undefined) {
            if(project.__typename === 'Design') {
              projectType = DesignFeature
            } else if(project.__typename === 'Research') {
              projectType = ResearchFeature
            } else if(project.__typename === "Music") {
              projectType = MusicFeature
            } else {
              projectType = ProjectFeature
            }
          }
          return projectType({ project, key: project.id, ...props})
        })}
      </Masonry>
    </ResponsiveMasonry>
  )
}