import React from "react"
// import Card from "react-bootstrap/Card"
import ProjectFeature from "./project-feature"
import {addClassName} from "../../utils"

export default function MusicFeature({ project, ...props }) {
  return (
    <ProjectFeature project={project} {...addClassName("feature-music", props)} />
  )
}
