import React from "react"
import Card from "react-bootstrap/Card"
import ProjectFeature from "./project-feature"
import { Stack } from "react-bootstrap"
import { 
  IoIosLink,
  IoLogoTwitter,
  IoLogoFacebook,
  IoLogoInstagram
} from "react-icons/io"
import {addClassName} from "../../utils"
import CardButton from "../ui/card-button"

export default function DesignFeature({ project, ...props }) {
  return (
    <ProjectFeature project={project} {...addClassName("feature-design", props)}>
      <Card.Footer className="pt-0">
        { project.collaborators 
          ? <Card.Text className="small text-muted">{project.collaborators}</Card.Text>
          : null}
        
        <Stack direction="horizontal" gap={3}>
          <span>{project.year}</span>
          { project.url 
            ? <CardButton to={project.url}>
                <IoIosLink />
                {` website`}
              </CardButton>
            : null }
          { project.twitter 
            ? (<CardButton to={`https://twitter.com/${project.twitter}`}>
                <IoLogoTwitter />
              </CardButton>)
            : null }
          { project.facebook 
            ? (<CardButton to={`https://facebook.com/${project.facebook}`}>
                <IoLogoFacebook />
              </CardButton>)
            : null }
          { project.instagram 
            ? (<CardButton href={`https://instagram.com/${project.instagram}`}>
                <IoLogoInstagram />
              </CardButton>)
            : null }
        </Stack>
      </Card.Footer>
    </ProjectFeature>
  )
}
